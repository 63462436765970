
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";
import NavTitle from "@/components/NavBar.vue";
import { Toast } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    StatusBar,
    NavTitle,
  },
})
export default class Set extends Mixins(Mixin) {
  onClickRight(): void {
    console.log(555);
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  goAccount(): void {
    if (!this.UserModule.isLogin) {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        goBackAfterLogin: true,
      });
      return;
    }
    monitorEvent("SetPage_ClickAccount", "点击账户与安全"); // 埋点：设置，点击账户与安全
    this.$router.push(`/personal-data/account`);
  }
  goContactm(): void {
    this.$router.push(`/personal-data/contactm`);
  }
  goFeedback(): void {
    this.$router.push(`/personal-data/feedback`);
  }
  goAbout(): void {
    this.$router.push(`/personal-data/about`);
  }
  LoginOut(): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.$api.oauth2Api.token.removeToken(() => {
      Toast.clear();
      this.UserModule.LOGOUT_PERSIST();
      this.$router.go(-1);
    });
  }
}
