
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import AddressDetail from "@/views/Mine/MoreServices/Address/AddressDetail.vue";

@Component({
  components: {
    AddressDetail,
  },
})
export default class ExternalAddAddressPage extends Mixins(Mixin) {
  public editAddresses = false;
  public edit = false;
  public editName = "";
  public addrDitailAddr = "";
  public editTel = "";
  public isDefault = "";
  public addrid = "";
  public addrarea = "";
  addrDispProvId = "";
  addrDispCityId = "";
  addrDispCounId = "";
  mounted() {
    this.$nextTick(() => {
      let addAddress = this.$refs["add-address"] as AddressDetail;
      if (addAddress) {
        this.edit = false;
        this.editAddresses = true;
      }
    });
  }
  refreshAddresslist(): void {
    this.jsBridge("back", "", () => {
      this.$router.go(-1);
    });
  }
  back(): void {
    this.jsBridge("back", "", () => {
      this.$router.go(-1);
    });
  }
}
